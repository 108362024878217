// src/components/PricingCalculator.jsx
import { useState } from 'react';
import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';

const PricingCalculator = () => {
  const [lessons, setLessons] = useState(1);
  const [duration, setDuration] = useState(60); // minuty
  const { isDark } = useTheme();

  const basePrice = 50; // cena za 60 min
  const calculatePrice = () => {
    const priceMultiplier = duration / 60;
    return (basePrice * priceMultiplier * lessons).toFixed(2);
  };

  const getLessonText = (count) => {
    if (count === 1) return 'lekcję';
    if (count % 10 >= 2 && count % 10 <= 4 && (count % 100 < 10 || count % 100 >= 20)) return 'lekcje';
    return 'lekcji';
  };

  const scrollToContact = () => {
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  };

  const handleLessonsChange = (e) => {
    const value = e.target.value;
    if (value === '') {
      setLessons('');
    } else {
      const numValue = parseInt(value);
      if (!isNaN(numValue)) {
        setLessons(Math.max(1, Math.min(20, numValue)));
      }
    }
  };

  return (
    <section className="container mx-auto px-4 py-16">
      <h2 className={`text-3xl font-bold text-center mb-12 ${
        isDark ? 'text-white' : 'text-gray-900'
      }`}>
        Cennik
      </h2>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={`max-w-md mx-auto ${
          isDark ? 'bg-gray-800 text-white' : 'bg-white'
        } rounded-lg shadow-lg p-6`}
      >
        <div className="space-y-6">
          <div>
            <label className="block mb-2">Liczba lekcji:</label>
            <input
              type="number"
              min="1"
              max="20"
              value={lessons}
              onChange={handleLessonsChange}
              onBlur={() => {
                if (lessons === '' || lessons < 1) {
                  setLessons(1);
                }
              }}
              className={`w-full p-2 border rounded ${
                isDark ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'
              }`}
            />
          </div>
          
          <div>
            <label className="block mb-2">Długość lekcji:</label>
            <select
              value={duration}
              onChange={(e) => setDuration(parseInt(e.target.value))}
              className={`w-full p-2 border rounded ${
                isDark ? 'bg-gray-700 border-gray-600' : 'bg-white border-gray-300'
              }`}
            >
              <option value={45}>45 minut</option>
              <option value={60}>60 minut</option>
              <option value={90}>90 minut</option>
            </select>
          </div>

          <div className="text-center">
            <div className="text-3xl font-bold mb-2">
              {lessons ? calculatePrice() : '0.00'} zł
            </div>
            <p className="text-sm text-gray-500">
              Cena za {lessons || 0} {getLessonText(lessons || 0)} ({duration} min)
            </p>
          </div>

          <button
            onClick={scrollToContact}
            className="w-full bg-blue-600 text-white py-3 px-6 rounded-lg hover:bg-blue-700 
                     transition-colors duration-300"
          >
            Zapisz się
          </button>
        </div>
      </motion.div>
    </section>
  );
};

export default PricingCalculator;