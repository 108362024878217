import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';

const questions = [
  {
    question: "She _____ a blind eye to her son's bad behavior.",
    options: ["made", "turned", "gave", "put"],
    correct: 1
  },
  {
    question: "Choose the correct idiomatic expression: The new employee passed their probation period with _____.",
    options: ["flying colors", "flying flags", "flying birds", "flying wings"],
    correct: 0
  },
  {
    question: "Select the correct phrasal verb: The company has decided to _____ several new projects next month.",
    options: ["embark on", "embark in", "embark at", "embark with"],
    correct: 0
  },
  {
    question: "It's high time you _____ about your future career.",
    options: ["think", "thought", "are thinking", "will think"],
    correct: 1
  },
  {
    question: "The report _____ by the end of next week.",
    options: ["will have been submitted", "will be submitting", "will submit", "would have submitted"],
    correct: 0
  },
  {
    question: "By this time next year, they _____ the bridge for six months.",
    options: ["will build", "will have been building", "would build", "will be building"],
    correct: 1
  },
  {
    question: "Choose the correct passive form: These issues _____ in tomorrow's meeting.",
    options: ["will be discussing", "will be discussed", "will have been discussing", "are being discussed"],
    correct: 1
  },
  {
    question: "Had I known about the meeting, I _____ attended it.",
    options: ["would have", "will have", "would had", "had"],
    correct: 0
  },
  {
    question: "No sooner _____ the door than the phone rang.",
    options: ["I had closed", "had I closed", "I closed", "did I close"],
    correct: 1
  },
  {
    question: "Little _____ that the decision would have such serious consequences.",
    options: ["did they know", "they knew", "did they knew", "they did know"],
    correct: 0
  },
  {
    question: "The project deadline _____ forward, we had to work overtime.",
    options: ["having been brought", "being brought", "has been brought", "was brought"],
    correct: 0
  },
  {
    question: "_____ they accepted our proposal, we would have started the project.",
    options: ["Should", "Had", "Would", "Were"],
    correct: 1
  },
  {
    question: "Choose the correct mixed conditional:",
    options: [
      "If I had studied harder, I would have a better job now.",
      "If I studied harder, I would have a better job now.",
      "If I had studied harder, I would had a better job now.",
      "If I would study harder, I would have a better job now."
    ],
    correct: 0
  },
  {
    question: "Choose the correct sentence:",
    options: [
      "Not until I arrived home did I realize I had lost my wallet.",
      "Not until I arrived home I realized I had lost my wallet.",
      "Not until I had arrived home I did realize I had lost my wallet.",
      "Not until I arrived home I did realize I had lost my wallet."
    ],
    correct: 0
  },
  {
    question: "_____ we to receive the funding, we would proceed with the expansion.",
    options: ["Would", "Were", "Should", "Had"],
    correct: 1
  }
];

const ReviewModal = ({ isOpen, onClose, questions, userAnswers, isDark }) => {
  const [currentReviewQuestion, setCurrentReviewQuestion] = useState(0);

  const getAnswerButtonClass = (optionIndex) => {
    const isCorrect = questions[currentReviewQuestion].correct === optionIndex;
    const wasSelected = userAnswers[currentReviewQuestion] === optionIndex;

    if (isCorrect) {
      return 'bg-green-500 text-white';
    }
    if (wasSelected && !isCorrect) {
      return 'bg-red-500 text-white';
    }
    return isDark ? 'bg-gray-700' : 'bg-gray-100';
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4"
      onClick={onClose}
    >
      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.95 }}
        className={`${
          isDark ? 'bg-gray-800 text-white' : 'bg-white text-gray-900'
        } rounded-xl shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto relative`}
        onClick={e => e.stopPropagation()}
      >
          {/* Header with close button */}
          <div className="sticky top-0 bg-inherit px-6 py-4 border-b border-gray-200 dark:border-gray-700 flex justify-end">
            <button
              onClick={onClose}
              className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
              aria-label="Close"
            >
              <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>

        {/* Content */}
        <div className="px-6 py-4">
          <div className="mb-6">
            <div className="h-2 w-full bg-gray-200 rounded">
              <div 
                className="h-2 bg-blue-500 rounded transition-all duration-300"
                style={{ width: `${((currentReviewQuestion + 1) / questions.length) * 100}%` }}
              ></div>
            </div>
            <p className="text-sm text-gray-500 mt-2">
              Pytanie {currentReviewQuestion + 1} z {questions.length}
            </p>
          </div>

          <h3 className="text-xl font-bold mb-4">
            {questions[currentReviewQuestion].question}
          </h3>

          <div className="space-y-3 mb-6">
            {questions[currentReviewQuestion].options.map((option, optionIndex) => (
              <div
                key={optionIndex}
                className={`w-full p-4 rounded-lg transition-colors duration-300 ${
                  getAnswerButtonClass(optionIndex)
                }`}
              >
                {option}
              </div>
            ))}
          </div>
        </div>

        {/* Footer with navigation buttons */}
        <div className="sticky bottom-0 bg-inherit px-6 py-4 border-t border-gray-200 dark:border-gray-700">
          <div className="flex justify-between">
            <button
              onClick={() => setCurrentReviewQuestion(prev => Math.max(0, prev - 1))}
              disabled={currentReviewQuestion === 0}
              className={`px-4 py-2 rounded-lg ${
                currentReviewQuestion === 0
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700'
              } text-white transition-colors duration-300`}
            >
              Poprzednie
            </button>
            <button
              onClick={() => setCurrentReviewQuestion(prev => Math.min(questions.length - 1, prev + 1))}
              disabled={currentReviewQuestion === questions.length - 1}
              className={`px-4 py-2 rounded-lg ${
                currentReviewQuestion === questions.length - 1
                  ? 'bg-gray-300 cursor-not-allowed'
                  : 'bg-blue-600 hover:bg-blue-700'
              } text-white transition-colors duration-300`}
            >
              Następne
            </button>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

const LevelAssessment = () => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [userAnswers, setUserAnswers] = useState(Array(questions.length).fill(null));
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const { isDark } = useTheme();

  const handleAnswer = (selectedIndex) => {
    const newUserAnswers = [...userAnswers];
    newUserAnswers[currentQuestion] = selectedIndex;
    setUserAnswers(newUserAnswers);

    if (selectedIndex === questions[currentQuestion].correct) {
      setScore(score + 1);
    }
    
    if (currentQuestion + 1 < questions.length) {
      setCurrentQuestion(currentQuestion + 1);
    } else {
      setShowResults(true);
    }
  };

  const getLevel = () => {
    const percentage = (score / questions.length) * 100;
    if (percentage >= 85) return "C1/C2";
    if (percentage >= 70) return "B2";
    if (percentage >= 50) return "B1";
    return "A2";
  };

  const getFeedback = () => {
    const percentage = (score / questions.length) * 100;
    if (percentage >= 85) {
      return "Twój poziom jest bardzo zaawansowany (C1/C2) i wykracza poza zakres oferowanych przeze mnie lekcji. Polecam poszukać native speakera lub lektora specjalizującego się w poziomach zaawansowanych.";
    } else if (percentage >= 70) {
      return "Bardzo dobry wynik! Jesteś na poziomie B2. Świetnie radzisz sobie z większością zaawansowanych struktur i możemy pracować nad dalszym rozwojem twoich umiejętności językowych.";
    } else if (percentage >= 50) {
      return "Twój poziom to B1. Masz solidne podstawy, ale warto skupić się na bardziej zaawansowanych strukturach gramatycznych i wzbogacaniu słownictwa. Wspólnie możemy pracować nad podniesieniem twojego poziomu.";
    } else {
      return "Jesteś na poziomie A2. Wspólnie skupimy się na utrwaleniu podstawowych struktur gramatycznych i systematycznym budowaniu słownictwa. Mam doświadczenie w pracy z osobami na tym poziomie.";
    }
  };
  
  const getDetailedFeedback = () => {
    const percentage = (score / questions.length) * 100;
    if (percentage >= 85) {
      return "Na tym poziomie zalecam:\n- Pracę z native speakerem\n- Kursy specjalistyczne (Academic English, Business English)\n- Przygotowanie do egzaminów CPE/IELTS/TOEFL\n- Czytanie zaawansowanej literatury akademickiej";
    } else if (percentage >= 70) {
      return "Warto skupić się na:\n- Mixed conditionals\n- Inversions\n- Zaawansowanych czasach gramatycznych\n- Idiomach i wyrażeniach kolokwialnych";
    } else if (percentage >= 50) {
      return "Sugerowane obszary do pracy:\n- Czasy Perfect\n- Strona bierna\n- Mowa zależna\n- Phrasal verbs";
    } else {
      return "Rekomendowane obszary nauki:\n- Podstawowe czasy gramatyczne\n- Podstawowe struktury zdaniowe\n- Regularne i nieregularne czasowniki\n- Podstawowe słownictwo";
    }
  };

  const reset = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowResults(false);
    setUserAnswers(Array(questions.length).fill(null));
    setIsReviewModalOpen(false);
  };

  return (
    <section className="container mx-auto px-4 py-16">
      <h2 className={`text-3xl font-bold text-center mb-12 ${
        isDark ? 'text-white' : 'text-gray-900'
      }`}>
        Sprawdź swój poziom
      </h2>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={`max-w-2xl mx-auto ${
          isDark ? 'bg-gray-800 text-white' : 'bg-white'
        } rounded-lg shadow-lg p-6`}
      >
        {!showResults ? (
          <>
            <div className="mb-6">
              <div className="h-2 w-full bg-gray-200 rounded">
                <div 
                  className="h-2 bg-blue-500 rounded transition-all duration-300"
                  style={{ width: `${((currentQuestion + 1) / questions.length) * 100}%` }}
                ></div>
              </div>
              <p className="text-sm text-gray-500 mt-2">
                Pytanie {currentQuestion + 1} z {questions.length}
              </p>
            </div>
            
            <h3 className="text-xl font-bold mb-4">
              {questions[currentQuestion].question}
            </h3>
            
            <div className="space-y-3">
              {questions[currentQuestion].options.map((option, index) => (
                <motion.button
                  key={index}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                  onClick={() => handleAnswer(index)}
                  className={`w-full p-4 text-left rounded-lg ${
                    isDark 
                      ? 'bg-gray-700 hover:bg-gray-600' 
                      : 'bg-gray-100 hover:bg-gray-200'
                  } transition-colors duration-300`}
                >
                  {option}
                </motion.button>
              ))}
            </div>
          </>
        ) : (
          <div className="text-center">
            <h3 className="text-2xl font-bold mb-4">
              Twój wynik: {score}/{questions.length}
            </h3>
            <p className="text-xl mb-4">
              Poziom: {getLevel()}
            </p>
            <p className="mb-6 text-lg">
              {getFeedback()}
            </p>
            <div className="mb-6 text-left bg-gray-100 dark:bg-gray-700 p-4 rounded-lg">
              <h4 className="font-bold mb-2">Szczegółowe rekomendacje</h4>
              <p className="whitespace-pre-line">
                {getDetailedFeedback()}
              </p>
            </div>
            <div className="flex gap-4 justify-center">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setIsReviewModalOpen(true)}
                className="bg-green-600 text-white px-8 py-3 rounded-lg hover:bg-green-700 
                         transition-colors duration-300"
              >
                Sprawdź odpowiedzi
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={reset}
                className="bg-blue-600 text-white px-8 py-3 rounded-lg hover:bg-blue-700 
                         transition-colors duration-300"
              >
                Spróbuj ponownie
              </motion.button>
            </div>
          </div>
        )}
      </motion.div>

      <AnimatePresence>
        {isReviewModalOpen && (
          <ReviewModal
            isOpen={isReviewModalOpen}
            onClose={() => setIsReviewModalOpen(false)}
            questions={questions}
            userAnswers={userAnswers}
            isDark={isDark}
          />
        )}
      </AnimatePresence>
    </section>
  );
};

export default LevelAssessment;