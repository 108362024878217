// src/components/Features.jsx
import { Clock, Monitor, BookOpen, Star } from 'lucide-react';
import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';

const FeatureCard = ({ icon: Icon, title, description, delay }) => {
  const { isDark } = useTheme();
  
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ delay, duration: 0.5 }}
      className={`${
        isDark ? 'bg-gray-800 text-white' : 'bg-white'
      } p-6 rounded-lg shadow-lg transform transition-all duration-300 hover:-translate-y-2`}
    >
      <Icon className="w-12 h-12 text-blue-500 mb-4" />
      <h3 className="text-xl font-bold mb-2">{title}</h3>
      <p className={isDark ? 'text-gray-300' : 'text-gray-600'}>{description}</p>
    </motion.div>
  );
};

const Features = () => {
  const { isDark } = useTheme();

  const features = [
    {
      icon: Clock,
      title: "Elastyczne godziny",
      description: "Dostosuj plan zajęć do swojego harmonogramu",
    },
    {
      icon: Monitor,
      title: "Nauka online",
      description: "Ucz się wygodnie z dowolnego miejsca",
    },
    {
      icon: Star,
      title: "Indywidualne podejście",
      description: "Lekcje dostosowane do twoich potrzeb",
    }
  ];

  return (
    <section className="container mx-auto px-4 py-16">
      <h2 className={`text-3xl font-bold text-center mb-12 ${
        isDark ? 'text-white' : 'text-gray-900'
      }`}>
        Dlaczego warto wybrać moje zajęcia?
      </h2>
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
        {features.map((feature, index) => (
          <FeatureCard
            key={index}
            {...feature}
            delay={index * 0.1}
          />
        ))}
      </div>
    </section>
  );
};

export default Features;