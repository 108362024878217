// src/components/ContactForm.jsx
import { motion } from 'framer-motion';
import { Phone, Mail, MessageCircle } from 'lucide-react';
import { useTheme } from '../contexts/ThemeContext';

const ContactInfo = () => {
  const { isDark } = useTheme();

  return (
    <section id="contact" className="container mx-auto px-4 py-16">
      <h2 className={`text-3xl font-bold text-center mb-12 ${
        isDark ? 'text-white' : 'text-gray-900'
      }`}>
        Kontakt
      </h2>
      
      <div className={`max-w-2xl mx-auto ${
        isDark ? 'bg-gray-800 text-white' : 'bg-white'
      } rounded-lg shadow-lg p-8`}>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="space-y-6"
        >
          {/* Contact Information */}
          <div className="space-y-4">
            <div className="flex justify-center items-center space-x-3">
              <Phone className="w-6 h-6 text-blue-500" />
              <p className="text-lg">+48 576 613 191</p>
            </div>       
          </div>

          {/* Messenger Link */}
          <div className="mt-8 pt-6 border-t border-gray-200 dark:border-gray-700">
            <p className="text-center mb-4">Lub skontaktuj się przez komunikator:</p>
            <div className="flex justify-center space-x-4">
              <motion.a
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                href="https://m.me/wiktor.polak.10"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-blue-500 text-white p-3 rounded-full hover:bg-blue-600 
                         transition-colors duration-300"
              >
                <MessageCircle className="w-6 h-6" />
              </motion.a>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default ContactInfo;