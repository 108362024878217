// src/App.jsx
import { ThemeProvider } from './contexts/ThemeContext';
import Header from './components/Header';
import ThemeToggle from './components/ThemeToggle';
import TutorSection from './components/TutorSection';
import Features from './components/Features';
import PricingCalculator from './components/PricingCalculator';
import LevelAssessment from './components/LevelAssessment';
import ContactForm from './components/ContactForm';

function App() {
  return (
    <ThemeProvider>
      <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50 
                    dark:from-gray-900 dark:to-gray-800 transition-colors duration-300">
        <ThemeToggle />
        
        <main className="relative">
          <Header />
          <TutorSection />
          <Features />
          <PricingCalculator />
          <LevelAssessment />
          <ContactForm />
        </main>

        <footer className="container mx-auto px-4 py-8 text-center text-gray-600 dark:text-gray-400">
          <p>© 2024 Korepetycje z Angielskiego. Wszystkie prawa zastrzeżone.</p>
        </footer>
      </div>
    </ThemeProvider>
  );
}

export default App;