import { motion } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';

const Header = () => {
  const { isDark } = useTheme();

  return (
    <header className="container mx-auto px-4 py-24 text-center max-w-4xl">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
        className="space-y-8"
      >
        <h1 className="text-6xl font-bold leading-tight">
          <span className={`${
            isDark 
              ? 'text-white' 
              : 'bg-gradient-to-r from-blue-600 via-purple-500 to-purple-600 text-transparent bg-clip-text'
          }`}>
            Angielski bez stresu i nudy
          </span>
        </h1>
        
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3, duration: 0.6 }}
          className={`text-xl ${isDark ? 'text-gray-300' : 'text-gray-600'} max-w-2xl mx-auto leading-relaxed`}
        >
          Nauka z rówieśnikiem, który wie, jak skutecznie ogarnąć język 
          {/* Opcjonalnie można dodać emoji */} ✨
        </motion.p>
      </motion.div>
    </header>
  );
};

export default Header;