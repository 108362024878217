// src/components/TutorSection.jsx
import { useTheme } from '../contexts/ThemeContext';
import { motion } from 'framer-motion';

const TutorSection = () => {
  const { isDark } = useTheme();

  return (
    <section className="container mx-auto px-4 py-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className={`max-w-4xl mx-auto ${
          isDark ? 'bg-gray-800' : 'bg-white'
        } rounded-xl shadow-lg overflow-hidden transition-colors duration-300`}
      >
        <div className="md:flex">
          <div className="md:flex-shrink-0">
            <img
              src="/tutor.png"
              alt="Zdjęcie korepetytora"
              className="h-full w-full object-cover md:w-48"
            />
          </div>
          <div className="p-8">
            <div className="uppercase tracking-wide text-sm text-blue-500 font-semibold">
              Twój korepetytor
            </div>
            <h2 className={`mt-2 text-2xl font-bold ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>Wiktor Polak</h2>
            <p className={`mt-2 ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
              Student filologii angielskiej z pasją do nauczania. Specjalizuję się 
              w konwersacjach i pomocy w przełamywaniu bariery językowej.
            </p>
            <div className="mt-4">
              <ul className={`space-y-2 ${isDark ? 'text-gray-300' : 'text-gray-600'}`}>
                <li>• 2 lata doświadczenia w nauczaniu</li>
                <li>• Specjalizacja w konwersacjach</li>
                <li>• Indywidualne podejście do każdego ucznia</li>
              </ul>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default TutorSection;